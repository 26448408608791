@import "../styles/modules.scss";

.popup {
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ui-black-t-90);
  position: fixed;
  z-index: 50;

  .container {
    width: 85vw;
    max-width: 50rem;
    max-height: calc(100vh - 6.25rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--ui-background-100);
    border: 1px solid var(--ui-dark);
    border-radius: 1.25rem;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 1.875rem;
      background: linear-gradient(
        180deg,
        var(--ui-background-100) 30%,
        transparent 100%
      );
      border-radius: 1.25rem;
      position: absolute;
      top: 0;
      z-index: 55;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1.875rem;
      background: linear-gradient(
        0deg,
        var(--ui-background-100) 30%,
        transparent 100%
      );
      border-radius: 1.25rem;
      position: absolute;
      bottom: 0;
      z-index: 55;
    }

    @include mobile {
      width: 100%;
      max-height: 100%;
      border-radius: 0;
      border: none;
    }

    .closeButton {
      width: 1.875rem;
      height: 1.875rem;
      background-image: url(../images/close.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
      z-index: 55;
      transition: transform ease-in-out 0.2s;
      -webkit-transition: transform ease-in-out 0.2s;
      cursor: pointer;

      &:active {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
      }
    }

    .content {
      padding: 1.875rem 3.125rem 1.875rem 1.875rem;
      display: flex;
      flex-direction: column;
      row-gap: 2.5rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;

      h3 {
        margin: 0 0 1.25rem 0.9375rem;
        font-family: "Roboto", "Arial", "Helvetica", sans-serif;
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.75rem;
        color: var(--text);

        @include mobile {
          margin-bottom: 0.625rem;
          font-size: 1.375rem;
        }
      }

      .illustration {
        margin-bottom: 1.25rem;
        font-size: 5.625rem;
        line-height: 5.625rem;
        shape-outside: circle(50%);

        &.left {
          margin-right: 1.25rem;
          float: left;
          transform: rotate(-20deg);
          -webkit-transform: rotate(-20deg);
        }

        &.right {
          margin-left: 1.25rem;
          float: right;
          transform: rotate(20deg);
          -webkit-transform: rotate(20deg);
        }

        @include mobile {
          margin-bottom: 0.625rem;
          font-size: 4.375rem;
          line-height: 4.375rem;
        }
      }

      p {
        font-family: "Roboto", "Arial", "Helvetica", sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--text);
      }

      .cardsContainer {
        display: flex;

        @include mobile {
          flex-direction: column;
        }

        .card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 0.3125rem;
          flex-grow: 1;

          &:first-of-type {
            margin-right: 0.9375rem;
            padding-right: 0.9375rem;
            border-right: 1px solid var(--ui-dark);
          }

          @include mobile {
            &:first-of-type {
              margin: 0;
              padding: 0;
              border-right: none;
              margin-bottom: 1.25rem;
              padding-bottom: 1.25rem;
              border-bottom: 1px solid var(--ui-dark);
            }
          }

          .bio {
            display: flex;
            flex-direction: column;
            font-family: "Roboto", "Arial", "Helvetica", sans-serif;
            font-weight: 700;
            white-space: nowrap;

            .name {
              font-size: 1rem;
              color: var(--text);
            }

            .profession {
              font-size: 0.875rem;
              color: var(--text-grey);
            }
          }

          .socialLinks {
            display: flex;
            column-gap: 1.25rem;

            .socialLink {
              width: 1.875rem;
              height: 1.875rem;
              background-repeat: no-repeat;
              background-size: contain;

              &.linkedIn {
                background-image: url(../images/linkedin.svg);
              }

              &.gitHub {
                background-image: url(../images/git.svg);
              }
            }
          }
        }
      }
    }
  }
}
