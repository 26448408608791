@import "../styles/modules.scss";

@keyframes popcornShake {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  20% {
    transform: rotate(12deg);
    -webkit-transform: rotate(12deg);
    filter: blur(0);
  }
  35% {
    transform: rotate(-12deg) scale(1.05);
    -webkit-transform: rotate(-12deg) scale(1.05);
  }
  50% {
    transform: rotate(12deg) scale(1.1);
    -webkit-transform: rotate(12deg) scale(1.1);
  }
  65% {
    transform: rotate(-12deg) scale(1.1);
    -webkit-transform: rotate(-12deg) scale(1.1);
    filter: blur(1px);
  }
  80% {
    transform: rotate(8deg) scale(1.1);
    -webkit-transform: rotate(8deg) scale(1.1);
  }
  90% {
    transform: rotate(-5deg) scale(1.05);
    -webkit-transform: rotate(-5deg) scale(1.05);
    filter: blur(0);
  }
  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}

.app {
  margin: 0;
  padding: 0;
  min-width: 100vw;
  height: 100dvh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--ui-background);
  font-family: "OpenSans", "Arial", "Helvetica", "sans-serif";
  overflow: hidden;
  position: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  &.noScroll {
    overflow: hidden;
  }

  .header {
    padding: 1.875rem 0.625rem;
    width: 90%;
    max-width: 81.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    @include mobile {
      padding: 1.25rem 0 0.625rem;
      width: 90%;
    }

    .title {
      display: flex;
      align-items: center;
      column-gap: 0.375rem;
      font-family: "Roboto", "Arial", "Helvetica", sans-serif;
      font-size: 2.125rem;
      font-weight: 700;
      white-space: nowrap;
      color: var(--text);
      cursor: pointer;

      @include hover {
        .popcorn {
          transform-origin: bottom center;
          animation: popcornShake 0.6s ease-in-out;
          -webkit-animation: popcornShake 0.6s ease-in-out;
        }
      }

      @include mobile {
        font-size: 1.75rem;
      }
    }

    .aboutButton {
      color: var(--text-grey);
      transition: color ease-in-out 0.2s, transform ease-in-out 0.2s;
      -webkit-transition: color ease-in-out 0.2s, transform ease-in-out 0.2s;
      cursor: pointer;

      @include hover {
        color: var(--text);
      }

      &:active {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
      }
    }
  }

  .content {
    padding: 0.625rem 0.625rem 1.875rem;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    row-gap: 1.25rem;

    @include mobile {
      padding: 1.25rem 0.625rem 0.9375rem;
    }

    .stepHeader {
      font-family: "Roboto", "Arial", "Helvetica", sans-serif;
      font-size: 1.75rem;
      font-weight: 700;
      color: var(--text);
      text-shadow: 0 0 0.625rem var(--ui-black);
      z-index: 1;

      @include mobile {
        font-size: 1.375rem;
      }
    }
  }
}
