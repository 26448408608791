@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 3.75rem;
  height: 3.75rem;
  background-image: url(../images/spinner.svg);
  background-repeat: no-repeat;
  background-size: contain;
  animation: rotate 2s linear infinite;
  -webkit-animation: rotate 2s linear infinite;
}

.error {
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  color: var(--ui-gray);
}
