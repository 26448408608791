@import "../styles/modules.scss";

.container {
  display: flex;
  gap: 1.875rem;
  z-index: 1;

  &.disabled {
    opacity: 0.25;
    pointer-events: none;
    cursor: not-allowed;
  }

  @include mobile {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0.75rem;
  }

  .skip {
    width: 1.875rem;
    height: 1.875rem;
    background-image: url(../images/eye-crossed.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transition: transform ease-in-out 0.2s;
    -webkit-transition: transform ease-in-out 0.2s;
    cursor: pointer;

    @include hover {
      background-image: url(../images/eye-crossed-white.svg);
    }

    &:active {
      transform: scale(0.95);
      -webkit-transform: scale(0.95);
    }
  }

  .starList {
    display: flex;
    user-select: none;

    .star {
      width: 1.875rem;
      height: 1.875rem;
      background-image: url(../images/star.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;

      &.hovered {
        background-image: url(../images/filled-star.svg);
      }
    }
  }

  .rating {
    width: 3.375rem;
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    align-self: flex-end;
    text-align: end;
    white-space: nowrap;
    color: var(--ui-gray);

    @include mobile {
      align-self: center;
      text-align: center;
    }
  }
}
