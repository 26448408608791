@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../vendor/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../vendor/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../vendor/fonts/Roboto-Bold.ttf") format("truetype");
}
