.container {
  display: flex;
  justify-content: center;
  column-gap: 0.9375rem;
  z-index: 1;

  .elementContainer {
    padding: 1px;
    width: 0.375rem;
    height: 0.375rem;
    border: 1px solid var(--ui-gray);
    border-radius: 0.3125rem;

    &.active {
      width: 6.25rem;
    }

    .progress {
      height: 100%;
      border-radius: 0.3125rem;
      background-color: var(--ui-gray);
      transition: width linear 0.25s;
      -webkit-transition: width linear 0.25s;
    }
  }
}
