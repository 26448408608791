@import "../styles/modules.scss";

.container {
  max-height: 36.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  row-gap: 1.25rem;

  .ambientContainer {
    min-height: 16.5625rem;
    height: 100%;
    display: flex;
    flex-grow: 1;
    aspect-ratio: 1 / 1.5;
    position: relative;
    perspective: 62.5rem;

    .filmList {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-grow: 1;
      z-index: 2;
      transform-style: preserve-3d;

      .filmCard {
        height: 100%;
        display: none;
        aspect-ratio: 1 / 1.5;
        position: absolute;
        transform-origin: bottom right;
        -webkit-transform-origin: bottom right;
        pointer-events: none;
        cursor: pointer;
        perspective: 62.5rem;
        -webkit-perspective: 62.5rem;

        .cardContainer {
          width: 100%;
          height: 100%;
          background-color: var(--ui-background-100);
          border: 1px solid var(--ui-dark);
          border-radius: 1.25rem;
          box-shadow: 0 0 1.5625rem 0.125rem var(--ui-black);
          position: relative;
          transform-style: preserve-3d;
          transition: transform ease-in-out 0.4s;
          -webkit-transition: transform ease-in-out 0.4s;

          &.backSide {
            transform: rotateY(180deg);
            --webkit-transform: rotateY(180deg);
          }

          .front {
            width: 100%;
            height: 100%;
            border-radius: 1.25rem;
            overflow: hidden;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            position: absolute;

            .cover {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              image-rendering: pixelated;
              transform: scale(1.08);
              -webkit-transform: scale(1.08);
            }
          }

          .back {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            transform: rotateY(-180deg);
            --webkit-transform: rotateY(-180deg);
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            .linkButton {
              margin: 0.625rem auto;
              padding: 0.625rem;
              font-family: "Roboto", "Arial", "Helvetica", sans-serif;
              white-space: nowrap;
              color: var(--text-grey);
              transition: color ease-in-out 0.2s;
              -webkit-transition: color ease-in-out 0.2s;
              -webkit-user-select: none;
              user-select: none;

              @include hover {
                color: var(--text);
              }
            }
          }
        }

        &.past {
          display: none;
        }

        &.previous {
          display: block;
          opacity: 0;
          z-index: 4;
          transition: opacity ease-in-out 0.4s;
          -webkit-transition: opacity ease-in-out 0.4s;
        }

        &.current {
          display: block;
          opacity: 1;
          z-index: 5;
          transform: rotate(0deg) scale(1);
          -webkit-transform: rotate(0deg) scale(1);
          transition: transform ease-in-out 0.4s, opacity ease-in-out 0.4s;
          -webkit-transition: transform ease-in-out 0.4s,
            opacity ease-in-out 0.4s;
          pointer-events: all;
        }

        &.next {
          display: block;
          z-index: 4;
          transform: rotate(20deg) scale(0.85) translateZ(-1.25rem);
          -webkit-transform: rotate(20deg) scale(0.85) translateZ(-1.25rem);
          transition: transform ease-in-out 0.4s;
          -webkit-transition: transform ease-in-out 0.4s;
        }

        &.prepared {
          display: block;
          z-index: 3;
          transform: rotate(35deg) scale(0.75) translateZ(-2.1875rem);
          -webkit-transform: rotate(35deg) scale(0.75) translateZ(-2.1875rem);
          transition: transform ease-in-out 0.4s;
          -webkit-transition: transform ease-in-out 0.4s;
        }

        &.future {
          display: block;
          z-index: 2;
          transform: rotate(35deg) scale(0.75) translateZ(-2.1875rem);
          -webkit-transform: rotate(35deg) scale(0.75) translateZ(-2.1875rem);
        }
      }
    }

    .ambient {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transform: scale(1.25) translateZ(-9.375rem);
      -webkit-transform: scale(1.25) translateZ(-9.375rem);
      filter: blur(2.8125rem);
      position: absolute;
    }
  }
}

.info {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  overflow-y: scroll;
  -webkit-user-select: none;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  &.short {
    padding: 1.25rem 0.75rem 0.75rem;
    height: 50%;
    justify-content: flex-end;
    row-gap: 0.3125rem;
    background: linear-gradient(
      0deg,
      var(--ui-black-t-90) 25%,
      transparent 100%
    );
    position: absolute;
    bottom: 0;
  }

  .matchPercentage {
    font-size: 1rem;
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    color: var(--text);

    span {
      font-weight: 700;
      color: var(--text-green);
    }
  }

  .title {
    font-size: 1rem;
    line-height: 1.25rem;
    text-shadow: 0 0 0.375rem var(--ui-black);
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-weight: 700;
    color: var(--text);

    &.short {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .year {
    font-size: 0.875rem;
    text-shadow: 0 0 0.375rem var(--ui-black);
    color: var(--text-grey);
  }

  .description {
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-shadow: 0 0 0.375rem var(--ui-black);
    color: var(--text);

    &.short {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
