@import "../styles/modules.scss";

.genreList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.625rem;

  .genre {
    padding: 0.375rem 0.625rem;
    background-color: var(--ui-dark);
    border-radius: 1.25rem;
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 1rem;
    text-transform: capitalize;
    white-space: nowrap;
    color: var(--text);
    transition: transform ease-in-out 0.2s;
    -webkit-transition: transform ease-in-out 0.2s;
    cursor: pointer;

    @include hover {
      background-color: var(--ui-white);
      color: var(--text-black);
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
      -webkit-transform: scale(0.95);
    }

    @include mobile {
      font-size: 1.125rem;
    }

    &.disabled {
      opacity: 0.25;
      transform: scale(0.95);
      -webkit-transform: scale(0.95);
      transition: transform ease-in-out 0.2s;
      -webkit-transition: transform ease-in-out 0.2s;
      pointer-events: none;
    }

    &.selected {
      background-color: var(--ui-white);
      color: var(--text-black);
      transform: scale(1.15);
      -webkit-transform: scale(1.15);
      transition: transform ease-in-out 0.4s;
      -webkit-transition: transform ease-in-out 0.4s;
      pointer-events: none;
    }
  }
}
