@import "../styles/modules.scss";

.container {
  display: flex;
  align-items: center;
  column-gap: 5rem;
  z-index: 1;

  button {
    width: 1.875rem;
    height: 1.875rem;
    background-repeat: no-repeat;
    background-size: contain;
    transition: transform ease-in-out 0.2s;
    -webkit-transition: transform ease-in-out 0.2s;
    cursor: pointer;

    &:active {
      transform: scale(0.95);
      -webkit-transform: scale(0.95);
    }

    &.left {
      background-image: url(../images/arrow-left.svg);

      @include hover {
        background-image: url(../images/arrow-left-white.svg);
      }
    }
    &.right {
      background-image: url(../images/arrow-right.svg);

      @include hover {
        background-image: url(../images/arrow-right-white.svg);
      }
    }

    &.disabled {
      opacity: 0.25;
      pointer-events: none;
    }
  }

  .orderNumber {
    width: 4.0625rem;
    font-family: "Roboto", "Arial", "Helvetica", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    text-shadow: 0 0 0.375rem var(--ui-black);
    color: var(--ui-gray);
  }
}
