:root {
  --ui-background: #0a0a0b;
  --ui-background-100: #0f0f10;
  --ui-white: #ebebeb;
  --ui-gray: #bebebe;
  --ui-dark: #212326;
  --ui-black: #000000;
  --ui-black-t-90: #000000e6;

  --text: #fff;
  --text-grey: #9d9d9d;
  --text-green: #007800;
  --text-black: #0d0d0d;
}
