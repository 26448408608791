$mobile_end: 767px;
$tablet_start: 768px;
$tablet_end: 1023px;
$desktop_start: 1024px;

@mixin mobile {
  @media (max-width: $mobile_end) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet_start) and (max-width: $tablet_end) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop_start) {
    @content;
  }
}
